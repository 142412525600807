
import { Component, Mixins } from 'vue-property-decorator';
import { TestimonialFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';
import { WebsiteLevel } from 'client-website-ts-library/types';

import TestimonialList from '../components/Testimonials/TestimonialList.vue';

@Component({
  components: {
    TestimonialList,
  },
})
export default class Testimonials extends Mixins(View) {
    private filter: TestimonialFilter = new TestimonialFilter({
      SearchLevel: WebsiteLevel.Office,
      SearchGuid: 'c7975393-d850-4519-98ef-4062ebb6bf53',
    });
}
